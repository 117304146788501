@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Unica+One&display=swap');

.font {
  font-family: 'Unica One', cursive;
}

.glow {
      /* font-size: 5rem; */
      line-height: 1em;
      color: white;
      text-shadow:
                  1px 1px 0px gold,
                  -3px -0 25px red,
                  3px 0px 3px white,
                  0px 0px 15px white,
                  15px 10px 10px black,
                  0 0 40px magenta,
                  0 0 40px purple,
                  0 0 60px magenta,
                  0 0 80px orangered,
                  0 0 80px magenta,
                  0 0 100px orangered,
                  0 0 150px orangered;
}

.secondary_text {
  font-size: 2rem;
  color: white;
}

.tertiary_text {
  font-size: 2rem;
  color: white;
}

.rectangle {
  height: 8px;
  width: 17.2rem;
  background-color: #FF2D92;
}

.rectangle:after {
  content: "";
  left: 0px;
  width: 0;
  height: 0;
}

.delay {
  position: relative;
  animation: 5s cssAnimation;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 2;
  }
}
