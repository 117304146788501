@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Montserrat:400');

html, body {
  width: 100%;
  height: 100%;
  background: #070f17;
}

.secondary_font {
  font-family: 'Montserrat';
}

.row {
  display:table-row;
}
