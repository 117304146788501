@import url('https://fonts.googleapis.com/css2?family=Unica+One&display=swap');

.unica {
  font-family: 'Unica One', cursive;
}

.icon_style {
  padding-left: 1.25rem;
  font-size: 60px;
  text-align: center;
}

.hover_text {
  padding-left: 1.25rem;
  text-align: center;
  color: pink;
}

.glow {
  text-shadow:
              1px 1px 0px gold,
              -3px -0 25px red,
              3px 0px 3px white,
              0px 0px 15px white,
              15px 10px 10px black,
              0 0 40px magenta,
              0 0 40px purple,
              0 0 60px magenta,
              0 0 80px orangered,
              0 0 80px magenta,
              0 0 100px orangered,
              0 0 150px orangered;
}

.delay {
  position: relative;
  animation: cssAnimation;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 2;
  }
}
